import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

export default function ProductImagePlaceholder({ alt }) {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 658, height: 658, layout: CONSTRAINED)
        }
      }
    }
  `);
  return <Img image={image.file.childImageSharp.gatsbyImageData} alt={alt} />;
}
